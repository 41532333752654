export default {
    referenceKey: "U",
    id: "U",
    title: "Impact of high drinking water nitrate levels on the endogenous formation of apparent N-nitroso compounds in combination with meat intake in healthy volunteers",
    datePublished: "2019-10-17",
    journal: " Environmental Health",
    authorPrimary: "Simone G. van Breda",
    authors: "Simone G. van Breda, Karen Mathijs, Virág Sági-Kiss, Gunter G. Kuhnle, Ben van der Veer, Rena R. Jones, Rashmi Sinha, Mary H. Ward and Theo M. de Kok",
    authorsHeading: "Breda",
    website: "",
    data: "",
    nitrateLevel: 1.5,
    diseases: ["carcinogens"],
    healthRisk: "",
    citation: "pages 1-12",
    type: "S",
    pages: 12,
    url: "/articles/u.pdf",
    keywords: ["Nitrate", "Nitrite", "Drinking water", "Processed red and unprocessed white meat", "Human dietary intervention study", "N-nitroso compounds", "Genotoxicity", "Vitamin C", "Endogenous nitrosation"],
    abstract: `<p><b>Background</b>: Nitrate is converted to nitrite in the human body and subsequently can react with amines and amides in the gastrointestinal tract to form N-nitroso compounds (NOCs), which are known to be carcinogenic in animals. Humans can be exposed to nitrate via consumption of drinking water and diet, especially green leafy vegetables and cured meat. The contribution of nitrate from drinking water in combination with meat intake has not been investigated thoroughly. Therefore, in the present pilot study, we examined the effect of nitrate from drinking water, and its interaction with the consumption of white and processed red meat, on the endogenous formation of NOCs, taking into account the intake of vitamin C, a nitrosation inhibitor</p>
    <br/>
    <p><b>Methods</b>: Twenty healthy subjects were randomly assigned to two groups consuming either 3.75 g/kg body weight (maximum 300 g per day) processed red meat or unprocessed white meat per day for two weeks. Drinking water nitrate levels were kept low during the first week (< 1.5 mg/L), whereas in week 2, nitrate levels in drinking water were adjusted to the acceptable daily intake level of 3.7 mg/kg bodyweight. At baseline, after 1 and 2 weeks, faeces and 24 h urine samples were collected for analyses of nitrate, apparent total N-nitroso compounds (ATNC), compliance markers, and genotoxic potential in human colonic Caco-2 cells</p>
    <br/>
    <p><b>Results</b>: Urinary nitrate excretion was significantly increased during the high drinking water nitrate period for both meat types. Furthermore, levels of compliance markers for meat intake were significantly increased in urine from subjects consuming processed red meat (i.e. 1-Methylhistidine levels), or unprocessed white meat (i.e. 3-Methylhistidine). ATNC levels significantly increased during the high drinking water nitrate period, which was more pronounced in the processed red meat group. Genotoxicity in Caco-2 cells exposed to faecal water resulted in increased genotoxicity after the interventions, but results were only significant in the low drinking water nitrate period in subjects consuming processed red meat. Furthermore, a positive correlation was found between the ratio of nitrate/vitamin C intake (including drinking water) and the level of ATNC in faecal water of subjects in the processed red meat group, but this was not statistically significant.</p><p>Conclusions: Drinking water nitrate significantly contributed to the endogenous formation of NOC, independent of the meat type consumed. This implies that drinking water nitrate levels should be taken into account when evaluating the effect of meat consumption on endogenous formation of NOC.</p>`,
    excerpts: ["Nitrate is converted to nitrite in the human body and subsequently can react with amines and amides in the gastrointestinal tract to form N-nitroso compounds (NOCs), which are known to be carcinogenic in animals.  Drinking water nitrate significantly contributed to the endogenous formation of NOC."],
}