import a from './articles/a'
import abc from './articles/abc'
import def from './articles/def'
import l from './articles/l'
import m from './articles/m'
import mno from './articles/mno'
import n from './articles/n'
import o from './articles/o'
import s from './articles/s'
import u from './articles/u'
// import g from './articles/g'
import g2 from './articles/g2'
import c from './articles/c'
import e from './articles/e'
import i from './articles/i'
import r from './articles/r'

export default {
  data: () => ({
    nitrateArticles: [a, abc, def, l, m, mno, n, o, s, u, c, e, i, g2, r]
  })
}
