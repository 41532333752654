export default {
  referenceKey: 'O',
  id: 'O',
  title:
    'Ingested Nitrate and Nitrite and Bladder Cancer in Northern New England',
  datePublished: '2020-01', // -d
  journal: 'Epidemiology',
  authorPrimary: 'Kathryn Hughes Barry',
  authors:
    'Kathryn Hughes Barry, Rena R. Jones, Kenneth P. Cantor, Laura E. Beane Freeman, David C. Wheeler, Dalsu Baris, Alison T. Johnson, G. M. Monawar Hosain, Molly Schwenn, Han Zhang, Rashmi Sinha, Stella Koutros, Margaret R. Karagas, Debra T. Silverman, Mary H. Ward',
  authorsHeading: 'Barry et al.',
  website: '',
  data: '',
  nitrateLevel: 2.07,
  diseases: ['bladder cancer'],
  healthRisk: '', //
  citation:
    'Epidemiology. 2020 January ; 31(1): 136–144. doi:10.1097/EDE.0000000000001112',
  type: 'S',
  pages: 18,
  url:
    '/articles/o.pdf',
  keywords: [
    'bladder cancer',
    'diet',
    'disinfection by-product',
    'drinking water contaminants',
    'nitrate',
    'nitrite',
    'trihalomethanes'
  ],
  abstract: `<p><b> Background</b>: N - nitroso compounds are hypothesized human bladder carcinogens.We investigated ingestion of N - nitroso compound precursors nitrate and nitrite from drinking water and diet and bladder cancer in the New England Bladder Cancer Study.
</p >
<br/>
<p>
<b>Methods</b>: Using historical nitrate measurements for public water supplies and measured and modeled values for private wells, as well as self-reported water intake, we estimated average nitrate concentrations (mg/L NO3-N) and average daily nitrate intake (mg/d) from 1970 to diagnosis/reference date (987 cases and 1,180 controls). We estimated overall and source-specific dietary nitrate and nitrite intakes using a food frequency questionnaire (1,037 cases and 1,225 controls). We used unconditional logistic regression to estimate odds ratios (OR) and 95% confidence intervals (CI). We evaluated interactions with factors that may affect N-nitroso compound formation (i.e., red meat, vitamin C, smoking), and with water intake.
</p>
<br/>
<p>
<b>Results</b>: Average drinking water nitrate concentration above the 95th percentile (>2.07 mg/L) compared with the lowest quartile (≤0.21 mg/L) was associated with bladder cancer (OR = 1.5, 95% CI = 0.97, 2.3; P trend = 0.01); the association was similar for average daily drinking water nitrate intake. We observed positive associations for dietary nitrate and nitrite intakes from processed meat (highest versus lowest quintile OR for nitrate = 1.4, 95% CI = 1.0, 2.0; P trend = 0.04; OR for nitrite = 1.5, 95% CI = 1.0, 2.1; P trend = 0.04, respectively), but not other dietary sources. We observed positive interactions between drinking water nitrate and red meat (P-interaction 0.05) and processed red meat (0.07).
</p>
<br/>
<p>
<b>Conclusions</b>: Our results suggest the importance of both drinking water and dietary nitrate sources as risk factors for bladder cancer.</p>`,
  excerpts: [
    `Average drinking water nitrate concentration above the 95th percentile (>2.07 mg/L) compared with the lowest quartile (< 0.21 mg/L) was associated with bladder cancer.`,
    `Our results suggest the importance of both drinking water and dietary nitrate sources as risk factors for bladder cancer.`
  ]
}
