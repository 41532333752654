export default {
    referenceKey: 'R',
    id: 'R',
    title:
        'A Population-Based Case-Control Study of Drinking-Water Nitrate and Congenital Anomalies Using Geographic Information Systems (GIS) to Develop Individual-Level Exposure Estimates',
    datePublished: '2014',
    journal: 'International Journal of Environmental Research and Public Health',
    authorPrimary: 'Caitlin E. Holtby',
    authors:
        'Caitlin E. Holtby, Judith R. Guernsey, Alexander C. Allen, John A. VanLeeuwen, Victoria M.Allen and Robert J.Gordon',
    authorsHeading: 'Holtby',
    website: '',
    data: '',
    nitrateLevel: 1.5,
    nitrateLevelNote: '(study exposure level: 1.5–5.56 mg/L)',
    diseases: ['congenital anomalies'],
    healthRisk: '',
    citation:
        'Int. J. Environ. Res. Public Health 2014, 11, 1803-1823; doi:10.3390/ijerph110201803',
    type: 'S',
    pages: 21,
    url:
        '/articles/r.pdf',
    keywords: [
        'Nitrate',
        'Congenital anomalies',
        'Drinking-water',
        'Geographic Information Systems (GIS)'
    ],
    abstract: `<p>Animal studies and epidemiological evidence suggest an association between prenatal exposure to drinking water with elevated nitrate (NO3-N) concentrations and incidence of congenital anomalies. This study used Geographic Information Systems (GIS) to derive individual-level prenatal drinking-water nitrate exposure estimates from measured nitrate concentrations from 140 temporally monitored private wells and 6 municipal water supplies. Cases of major congenital anomalies in Kings County, Nova Scotia, Canada, between 1988 and 2006 were selected from province-wide population-based perinatal surveillance databases and matched to controls from the same databases. Unconditional multivariable logistic regression was performed to test for an association between drinking-water nitrate exposure and congenital anomalies after adjusting for clinically relevant risk factors. Employing all nitrate data there was a trend toward increased risk of congenital anomalies for increased nitrate exposure levels though this was not statistically significant. After stratification of the data by conception before or after folic acid supplementation, an increased risk of congenital anomalies for nitrate exposure of 1.5–5.56 mg/L (2.44; 1.05–5.66) and a trend toward increased risk for >5.56 mg/L (2.25; 0.92–5.52) was found. Though the study is likely underpowered, these results suggest that drinking-water nitrate exposure may contribute to increased risk of congenital anomalies at levels below the current Canadian maximum allowable concentration.
</p >`,
    excerpts: [
        `...drinking-water nitrate exposure may contribute to increased risk of congenital anomalies below the current Canadian maximum allowable concentration.`
    ]
}