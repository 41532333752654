export default {
  referenceKey: 'Q',
  id: 'Q',
  title: 'Nitrate-Nitrogen Levels in Rural Drinking Water: Is There anAssociation with Age-related Macular Degeneration?',
  datePublished: '2013',
  journal: 'Journal of Environmental Science and Health',
  authorPrimary: 'Barbara E. K. Klein',
  authors: 'Barbara E. K. Klein, Jane A. McElroy, Ronald Klein, Kerri P. Howard, and Kristine E. Lee',
  authorsHeading: 'Klein et al.',
  website: 'https://doi.org/10.1186/s12940-018-0442-6', //
  data: 'https://doi.org/10.1186/s12940-018-0442-6', //
  nitrateLevel: '5.0', // 5-9 medium level, 10+ for high
  diseases: ['macular degeneration'],
  healthRisk: 'macular degeneration',
  citation: '',
  type: 'S',
  pages: 12,
  url: '/articles/q.pdf', //
  keywords: [
    'Disease',
    'Epidemiology',
    'Personal exposure',
    'Population based studies'
  ], //
  abstract: '<p>We examined the association of nitrate-nitrogen exposure from rural private drinking water and incidence of age-related macular degeneration (AMD). Participants of the Beaver Dam Eye Study living in rural areas within the 53916 zone improvement plan code but outside the city limits of Beaver Dam, Wisconsin (Beaver Dam Township) completed a questionnaire and ocular examination including standardized, graded fundus photographs at five examinations. Data from an environmental monitoring study with probabilistic-based agro-chemical sampling, including nitrate-nitrogen, of rural private drinking water were available. Incidence of early AMD was associated with elevated nitrate-nitrogen levels in rural private drinking water supply (10.0% for low, 19.2% for medium, and 26.1% for high nitrate-nitrogen level in the right eye). The odds ratios (ORs) were 1.77 (95% confidence interval [CI]: 1.12–2.78) for medium and 2.88 (95% CI: 1.59–5.23) for high nitrate-nitrogen level. Incidence of late AMD was increased for those with medium or high levels of nitrate-nitrogen compared to low levels (2.3% for low and 5.1% for the medium or high nitrate-nitrogen level, for the right eye). The OR for medium or high nitrate-nitrogen groups was 2.80 (95% CI: 1.07–7.31) compared to the low nitrate-nitrogen group.</p>',
  excerpts: [
    "Incidence of early AMD was associated with elevated nitrate-nitrogen levels in rural private drinking water supply."
  ]
}
