export default {
    referenceKey: "I",
    id: "I",
    title: "Nitrate from Drinking Water and Diet and Bladder Cancer Among Postmenopausal Women in Iowa",
    datePublished: "2016-11",
    journal: "Environmental Health Perspectives",
    authorPrimary: "Rena R Jones",
    authors: "Rena R Jones, Peter J Weyer, Curt T DellaValle, Maki Inoue-Choi, Kristin E Anderson, Kenneth P Cantor, Stuart Krasner, Kim Robien, Laura E Beane Freeman, Debra T Silverman, Mary H Ward",
    authorsHeading: "Jones",
    website: "https://pubmed.ncbi.nlm.nih.gov/27258851/",
    data: "",
    nitrateLevel: 5,
    diseases: ["bladder cancer"],
    healthRisk: "",
    citation: "pages 1751-1758",
    type: "S",
    pages: 8,
    url: "/articles/i.pdf",
    keywords: [],
    abstract: `<p><b>Background: </b>Nitrate is a drinking water contaminant arising from agricultural sources, and it is a precursor in the endogenous formation of N-nitroso compounds (NOC), which are possible bladder carcinogens.
<br/>
<p><b>Objectives: </b>We investigated the ingestion of nitrate and nitrite from drinking water and diet and bladder cancer risk in women.</p>
<br/>
<p><b>Methods: </b>We identified incident bladder cancers among a cohort of 34,708 postmenopausal women in Iowa (1986-2010). Dietary nitrate and nitrite intakes were estimated from a baseline food frequency questionnaire. Drinking water source and duration were assessed in a 1989 follow-up. For women using public water supplies (PWS) > 10 years (n = 15,577), we estimated average nitrate (NO3-N) and total trihalomethane (TTHM) levels and the number of years exceeding one-half the maximum contaminant level (NO3-N: 5 mg/L, TTHM: 40 μg/mL) from historical monitoring data. We computed hazard ratios (HRs) and 95% confidence intervals (CIs), and assessed nitrate interactions with TTHM and with modifiers of NOC formation (smoking, vitamin C).</p>
<br/>
<p><b>Results: </b>We identified 258 bladder cancer cases, including 130 among women > 10 years at their PWS. In multivariable-adjusted models, we observed nonsignificant associations among women in the highest versus lowest quartile of average drinking water nitrate concentration (HR = 1.48; 95% CI: 0.92, 2.40; ptrend = 0.11), and we found significant associations among those exposed ≥ 4 years to drinking water with > 5 mg/L NO3-N (HR = 1.62; 95% CI: 1.06, 2.47; ptrend = 0.03) compared with women having 0 years of comparable exposure. TTHM adjustment had little influence on associations, and we observed no modification by vitamin C intake. Relative to a common reference group of never smokers with the lowest nitrate exposures, associations were strongest for current smokers with the highest nitrate exposures (HR = 3.67; 95% CI: 1.43, 9.38 for average water NO3-N and HR = 3.48; 95% CI: 1.20, 10.06 and ≥ 4 years > 5 mg/L, respectively). Dietary nitrate and nitrite intakes were not associated with bladder cancer.</p>
<br/>
<p><b>Conclusions: </b>Long-term ingestion of elevated nitrate in drinking water was associated with an increased risk of bladder cancer among postmenopausal women.</p>
<br/>
<p><b>Citation: </b> Jones RR, Weyer PJ, DellaValle CT, Inoue-Choi M, Anderson KE, Cantor KP, Krasner S, Robien K, Beane Freeman LE, Silverman DT, Ward MH. 2016. Nitrate from drinking water and diet and bladder cancer among postmenopausal women in Iowa. Environ Health Perspect 124:1751-1758; http://dx.doi.org/10.1289/EHP191.</p>`,
    excerpts: ["We found significant associations among those exposed ≥ 4 years to drinking water with > 5 mg/L NO3-N.", "Long-term ingestion of elevated nitrate in drinking water was associated with an increased risk of bladder cancer among postmenopausal women."],
}