export default {
    referenceKey: "A",
    id: "A",
    title: "Survey of Literature Relating to Infant Methemoglobinemia Due to Nitrate-Contaminated Water",
    datePublished: "1951-08",
    journal: "American Journal of Public Health",
    authorPrimary: "Graham Walton",
    authors: "Graham Walton",
    authorsHeading: "Walton",
    website: "",
    data: "",
    nitrateLevel: 10,
    diseases: ["methemoglobinemia"],
    healthRisk: "",
    citation: "pages 986-996",
    type: "S",
    pages: 11,
    url: "/articles/a.pdf",
    keywords: "",
    abstract: `<p>METHEMOGLOBINEMIA is due to the presence of methemoglobin in the blood. It may result from the reaction of any one of several oxidizing agents with the hemoglobin normally present in the blood. Unlike the oxygen in hemoglobin, that in methemoglobin is so firmly bound that the methemo­globin cannot function as an oxygen carrier by alternate oxygenation and deoxygenation.  Thus, the result may be anoxemia with serious consequences or even death.  </p>
    <br/>
    <p>Although methemoglobinemia may re­sult from congenital heart diseases, or from the ingestion, inhalation, or absorp­tion, or the medicinal administration, of any one of several drugs or chemicals, an important cause of cases in infants is the ingestion of water </p>`,
    excerpts: ["...an important cause of [methemoglobinemia] in infants is the ingestion of water high in nitrate."],
}