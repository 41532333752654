export default {
  referenceKey: 'L',
  id: 'L',
  title:
    'Nitrate in drinking water and colorectal cancer risk: A nationwide population-based cohort study',
  datePublished: '2018-02-13',
  journal: 'International Journal of Cancer',
  authorPrimary: 'Jorg Schullehner',
  authors:
    'Jorg Schullehner, Birgitte Hansen, Malene Thygesen, Carsten B. Pedersen and Torben Sigsgaard',
  authorsHeading: 'Schullehner et al.',
  website: 'https://doi.org/10.1186/s12940-018-0442-6',
  data: 'https://doi.org/10.1186/s12940-018-0442-6',
  nitrateLevel: 3.87,
  diseases: ['colorectal cancer'],
  healthRisk: 'colorectal cancer', //
  citation: 'Int. J. Cancer: 143, 73–79 (2018)',
  type: 'S',
  pages: 7,
  url: '/articles/l.pdf', //
  pdf: true,
  keywords: [
    'nitrate',
    'drinking water',
    'colorectal cancer',
    'Denmark',
    'cohort studies'
  ],
  abstract:
    '<p>Nitrate in drinking water may increase risk of colorectal cancer due to endogenous transformation into carcinogenic N-nitroso compounds. Epidemiological studies are few and often challenged by their limited ability of estimating long-term exposure on a detailed individual level. We exploited population-based health register data, linked in time and space with longitudinal drinking water quality data, on an individual level to study the association between long-term drinking water nitrate exposure and colorectal cancer (CRC) risk. Individual nitrate exposure was calculated for 2.7 million adults based on drinking water quality analyses at public waterworks and private wells between 1978 and 2011. For the main analyses, 1. 7 million individuals with highest exposure assessment quality were included. Follow-up started at age 35. We identified 5,944 incident CRC cases during 23 million person-years at risk. We used Cox proportional hazards models to estimate hazard ratios (HRs) of nitrate exposure on the risk of CRC , colon and rectal cancer. Persons exposed to the highest level of drinking water nitrate had an HR of 1.16 (95% Cl: 1.08-1.25) for CRC compared with persons exposed to the lowest level. We found statistically significant increased risks at drinking water levels above 3.87 mg/L, well below the current drinking water standard of 50 mg/L. <br>Our results add to the existing evidence suggesting increased CRC risk at drinking water nitrate concentrations below the current drinking water standard. A discussion on the adequacy of the drinking water standard in regards to chronic effects is warranted.</p>',
  excerpts: ["We found statistically significant increased risks at drinking water levels above 3.87 mg/L."]
}
