export default {
  referenceKey: 'S',
  id: 'S',
  title: 'Septic systems as sources of organic wastewater compounds in domestic drinking water wells in a shallow sand and gravel aquifer.',
  datePublished: '2016-01-27',
  journal: 'Science of the Total Environment',
  authorPrimary: 'Laurel A. Schaider',
  authors: 'Laurel A. Schaider, Janet M. Ackerman, Ruthann A. Rudel',
  authorsHeading: 'L.A. Schaider et al.',
  website: 'www.elsevier.com/locate/scitotenv',
  data: 'https://doi.org/10.1016/j.envres.2019.04.009', //
  nitrateLevel: '1.0',
  diseases: ['caused by OWCs and PFASs'], //
  healthRisk: '', //
  citation: 'Science of the Total Environment 547 (2016) 470–481',
  type: 'S',
  pages: 12, //
  url: '', //
  keywords: ['PFASs', 'OWCs'], //
  abstract: 'Domestic drinking water wells serve 44 million people in the US and are common globally. They are often located in areas served by onsite wastewater treatment systems, including septic systems, which can be sources of biological and chemical pollutants to groundwater. In this study we tested 20 domestic drinking water wells in a sand and gravel aquifer on Cape Cod, Massachusetts, USA, for 117 organic wastewater compounds (OWCs) and for inorganic markers of septic system impact. We detected 27 OWCs, including 12 pharmaceuticals, five per- and polyfluoroalkyl substances (PFASs), four organophosphate flame retardants, and an artificial sweetener (acesulfame). Maximum concentrations of several PFASs and pharmaceuticals were relatively high compared to public drinking water supplies in the US. The number of detected OWCs and total concentrations of pharmaceuticals and of PFASs were positively correlated with nitrate, boron, and acesulfame and negatively correlated with well depth. These wells were all located in areas served exclusively by onsite wastewater treatment systems, which are likely the main source of the OWCs in these wells, although landfill leachate may also be a source. Our results suggest that current regulations to protect domestic wells from pathogens in septic system discharges do not prevent OWCs from reaching domestic wells, and that nitrate, a commonly measured drinking water contaminant, is a useful screening tool for OWCs in domestic wells. Nitrate concentrations of 1 mg/L NO3-N, which are tenfold higher than local background and tenfold lower than the US federal drinking water standard, were associated with wastewater impacts from OWCs in this study.',
  excerpts: ['Nitrate concentrations of 1 mg/L NO3-N, which are tenfold higher than local background and tenfold lower than the US federal drinking water standard, were associated with wastewater impacts from OWCs [organic wastewater compounds]...'],
  url: "/articles/s.pdf"
}
