export default {
  referenceKey: 'P',
  id: 'P',
  title: 'Nitrate Toxicity And Drinking Water Standards – A Review',
  //title : "NITRATE TOXICITY AND DRINKING WATER STANDARDS – A REVIEW",
  datePublished: '2002',
  journal: 'Journal of Preventive Medicine',
  authorPrimary: 'B.C. Kross',
  authors: 'B.C. Kross',
  authorsHeading: 'B.C. Kross',
  website: 'https://doi.org/10.1186/s12940-018-0442-6', //
  data: 'https://doi.org/10.1186/s12940-018-0442-6', //
  nitrateLevel: 4.4, // 4.4, 5.0, and 5.6 mentioned
  diseases: ['MCL and HAL'], //
  healthRisk: '', //
  citation: '2002; 10 (1): 3-10 ',
  type: 'S',
  pages: 8,
  url: '/articles/p.pdf', //
  keywords: ['nitrate', 'drinking water', 'safety factor', 'methemoglobinemia'], //
  abstract: '<p>The current US EPA maximum contaminant level (MCL) for public drinking water supplies and the health advisory level (HAL) for other private water supplies is 10 mg/L, expressed as NO3-N. Unlike other drinking water standards, the nitrate standard has <u>no safety factor</u>, which typically is about a 10-fold safety factor to account for differences in human susceptibility. Guidance, action, or advisory levels for nitrate in drinking water are lower in several countries, including Germany (4.4 mg/L), South Africa (4.4 mg/L), and Denmark (5.6 mg/L). Clearly health and regulatory officials in other countries believe that the current WHO and USA drinking water standard for nitrate is not adequate to protect their most susceptible population from methemoglobinemia. In addition to acute methemoglobinemia, other potential health effects of nitrate exposure in drinking water include cancer, disruption of thyroid function, birth defects, and developmental disorders in children. Is the current drinking water standard in the US and World Health Organization for nitrate adequate? I think not! Clearly the most susceptible human population (infants under four months of age with existing diarrhea conditions) is not adequately protected from methemoglobinemia. In addition, recent studies suggest other possible linkages between nitrate in drinking water and adverse health consequences for adults. Particularly troublesome is the finding of a positive association between nitrate in drinking water (at levels below the USA drinking water standard) and bladder cancer and ovarian cancer in a large cohort of women in Iowa, USA. Given this framework, the regulatory authorities should establish a safety factor of two, which would reduce the current MCL and HAL for nitrate to 5.0 mg/L NO3-N. This regulatory mandate would encourage a prudent public health strategy of limiting human nitrate exposure.</p>',
  excerpts: [
    `Unlike other drinking water standards, the nitrate standard has no safety factor, which typically is about a 10-fold safety factor to account for differences in human susceptibility. Guidance, action, or advisory levels for nitrate in drinking water are lower in several countries, including Germany (4.4 mg/L), South Africa (4.4 mg/L), and Denmark (5.6 mg/L).`
  ]
}
