export default {
    referenceKey: "C",
    id: "C",
    title: "Drinking water nitrate and the risk of non-Hodgkin's lymphoma",
    datePublished: "1996-Sep",
    journal: "Epidemiology/National Cancer Institute",
    authorPrimary: "Ward",
    authors: "M H Ward, S D Mark, K P Cantor, D D Weisenburger, A Correa-Villaseñor, S H Zahm",
    authorsHeading: "Ward",
    websites: ["https://pubmed.ncbi.nlm.nih.gov/8862975/", "https://journals.lww.com/epidem/abstract/1996/09000/drinking_water_nitrate_and_the_risk_of.3.aspx"],
    data: "",
    nitrateLevel: 4,
    diseases: ["non-Hodgkin's lymphoma"],
    healthRisk: "non-Hodgkin's lymphoma",
    citation: "Epidemiology: September 1996 - Volume 7 - Issue 5 - p 465-471",
    type: "S",
    pages: 7,
    url: "/articles/c.pdf",
    keywords: ["non-Hodgkin's lymphoma", "nitrate", "drinking water", "diet"],
    abstract: "<p>The increasing incidence of non-Hodgkin's lymphoma (NHL) in the United States is only partially explained by known risk factors. Nitrate is a contaminant of drinking water in many rural areas. We evaluated its association with NHL after accounting for dietary nitrate intake. For 156 cases and 527 controls who used Nebraska community supplies, average nitrate exposure was estimated from 1947 through 1979. Longterm consumption of community water with average nitrate levels in the highest quartile (≥4 mg per liter nitrate-nitrogen) was positively associated with risk [odds ratio (OR) = 2.0; 95% confidence interval (CI) = 1.1-3.6]. Dietary nitrate, which came mainly from vegetables, was not associated with NHL risk, after adjusting for vitamin C and carotene intakes. Persons with a lower intake of vitamin C were at slightly higher risk of developing NHL than persons whose daily intake was ≥130 mg, for all levels of intake of drinking water nitrate; our findings were similar for the combined effect of water nitrate and carotene intake. Nitrate levels in private wells were measured at the time of the interview for 51 cases and 150 controls but were not associated with the risk of NHL after adjusting for pesticide use on the farm. These findings indicate that longterm exposure to elevated nitrate levels in drinking water may contribute to the risk of NHL.</p>",
    excerpts: ["...longterm exposure to elevated nitrate levels in drinking water may contribute to the risk of NHL."],
}